import consumer from "./consumer"

$(document).ready(function() {
    // トークルームページ以外では実行しない
    if( !location.pathname.includes('/chat_room/') && !$('#room-id').length ){
        return;
    }

    const channel = "ChatRoomChannel"
    const roomId = $('#room-id').val();
    const secret = $('#room-secret').val();
    const type   = $('#secret-type').val();
    const userId = $('#user-id').val();
    const counselorId = $('#counselor-id').val();

    // 同じユーザーが2つ以上のチャンネルを同時に購読できないようにする他のトークルームはリアルタイムにならないだけで、更新すれば受信したものは見れる
    // const userIdentifier = `"user_id":"${userId}"`;
    // const subscriptions = consumer.subscriptions.subscriptions;
    // console.log(subscriptions);
    // if(subscriptions){
    //   subscriptions.map(function userUnsubscribe(subscription){
    //     if(subscription.identifier.includes(userIdentifier)){
    //       subscription.consumer.subscriptions.remove(subscription)
    //     }
    //   });
    // }
    let userIdentifier = `"room_id":"${roomId}"`;
    let subscriptions = consumer.subscriptions.subscriptions;
    if(subscriptions){
        subscriptions.map(function userUnsubscribe(subscription){
            if(subscription.identifier.includes(userIdentifier)){
                subscription.consumer.subscriptions.remove(subscription)
            }
        });
    }

    const chatRoom = consumer.subscriptions.create(
        {
            // チャンネル情報とルーム情報の定義
            channel: channel, room_id: roomId, secret: secret, counselor_id: counselorId, user_id: userId, type: type
        },
        {
            connected() {
                // Called when the subscription is ready for use on the server
                console.log('channel_connected');
                //再接続ボタンを非表示にする。
                $('.reconnect').css('display', 'none');

                // アクセス時に画面下まで自動スクロール
                $('html, body').animate(
                    { scrollTop: $('body').height() }, 'slow'
                );
            },

            disconnected() {
                // Called when the subscription has been terminated by the server
                console.log('channel_disconnected');
                // トークルーム内で接続が切断された場合は、再接続ボタンを表示
                if( location.pathname.includes('/chat_room/') && $('#room-id').length ){
                    $('.reconnect').css('display', 'block');
                }
            },

            // dataを受け取ったらチャット欄にリアルタイム表示
            received(data) {
                $.ajax({
                    url: '/myshiru/chat_room/get_message_partial',
                    type: 'POST',
                    data: {
                        message_id: data['message_id'],
                        room_id:    data['room_id'],
                        secret:     data['secret'],
                    }
                })
                    .done(function(ret){
                        // DOMにメッセージを追加して表示
                        const messages = document.getElementById('messages');
                        messages.insertAdjacentHTML('beforeend', ret['html']);

                        // ページ下部まで自動スクロール
                        $('html, body').animate(
                            { scrollTop: $('body').height() }, 'slow'
                        );

                        // メッセージタイプがmessage以外の場合、トークルームのパーシャルを更新する。
                        if( ret['message_type'] !== 'message' ){
                            $.ajax({
                                url: '/myshiru/chat_room/reload_reservation_info',
                                type: 'POST',
                                data: {
                                    room_id: $('#room-id').val(),
                                    secret:  $('#room-secret').val(),
                                }
                            })
                            // .done(function(){ console.log('reload reservation info success'); })
                            // .fail(function(){ console.log('reload reservation info failed'); });
                        }
                    });
            },

            // ユーザーからのチャット送信
            sendChatUser: function(message, roomId, secret) {
                return this.perform('send_chat_user',
                    {
                        message: message,
                        room_id: roomId,
                        secret: secret
                    });
            }
        }
    );

    // 送信ボタンをクリックで送信
    $('#send-chat-user').on('click', function() {
        sendChat();
    });

    // テキストボックス上で、Ctrl+Enter OR Command+Enter で送信
    $('#send-chat-text').on('keydown', function(event) {
        // テキストの変換中なら何もしない
        if (event.originalEvent.isComposing) {
            return;
        }
        if( (event.ctrlKey |　event.metaKey) && event.key == 'Enter') {
            sendChat();
            event.preventDefault();
        }
    });

    // チャット送信
    function sendChat(){
        const message = $('#send-chat-text').val();
        if(message.trim()!=""){
            chatRoom.sendChatUser(message, roomId, secret);
            $('#send-chat-text').val('');
        }
    }

    // 接続操作用カスタムイベントを定義
    $("#event-caller-ws-disconnect").on('click', function(e){
        $("#messages").trigger('wsDisconnect');
    });
    $("#event-caller-ws-connect").on('click', function(e){
        $("#messages").trigger('wsConnect');
    });
    $("#messages").on('wsDisconnect', function(e){
        chatRoom.consumer.disconnect();
        console.log('wsDisconnect');
    });
    $("#messages").on('wsConnect', function(e){
        chatRoom.consumer.connect();
        console.log('wsConnect');
    });

    // 受信漏れ対策として、定期的にメッセージの更新を確認する
    let serviceType = $('#service-type').val();
    let paymentMessageName = "";
    let completeMessageName = "";
    switch (serviceType) {
        case 'is-instant-telephone':
            paymentMessageName = ".payment_request_instant_telephone";
            completeMessageName = ".payment_complete_instant_telephone, .payment_complete_instant_telephone_prepaid";
            break;
        case 'is-chat':
            paymentMessageName = ".payment_request_chat";
            completeMessageName = ".payment_complete_chat";
            break;
        default:
            break;
    }
    if(paymentMessageName!=="" && completeMessageName!==""){
        // 決済リクエストと完了のメッセージ有無を確認し、両方ともDOMに存在しない場合はチェック関数を呼び出す
        if ( ! $(paymentMessageName).length && ! $(completeMessageName).length ) {
            checkPaymentRequest(serviceType);
        }
    }

    // 決済リクエストのメッセージ有無を確認
    function checkPaymentRequest(serviceType){
        let retryMilliSec = 120000; // 繰り返し間隔2分
        // パスが/reservation/chat_room/から移動している場合はチェックしない
        if( !location.pathname.includes('/chat_room/') ){
            console.log('not chat_room page. check payment request canceled.')
            return;
        }
        // 既に支払い系メッセージがある場合はチェックしない
        if ( $(paymentMessageName).length || $(completeMessageName).length ) {
            console.log('already payment request message.')
            return;
        }
        $.ajax({
            url: '/myshiru/chat_room/check_payment_request',
            type: 'POST',
            data: {
                room_id: $('#room-id').val(),
                secret:  $('#room-secret').val(),
                service_type: serviceType
            }
        })
        .done(function(ret){
            if(ret['result'] === "found"){
                // チャット入力中でなければ場合のみリロード
                if( !$('#send-chat-text').is(':focus') ){
                    location.reload();
                }else{
                    // 2分後に再度チェック
                    setTimeout(function() { checkPaymentRequest(serviceType); }, retryMilliSec);
                    // console.log('payment request found. but chat inputting.');
                }
            }else{
                // 2分後に再度チェック
                setTimeout(function() {checkPaymentRequest(serviceType);}, retryMilliSec);
                // console.log('payment request not found.');
            }
        })
        .fail(function(){
            // 2分後に再度チェック
            setTimeout(function() {checkPaymentRequest(serviceType);}, retryMilliSec);
            // console.log('check payment request failed');
        });

    }

});
